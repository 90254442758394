import videojs from 'video.js';

import 'legacy/vendor/jquery.iosslider';
import { initVideo } from 'legacy/video/index.js';

function initVSOTDButtons() {
  $('#carousel-wrapper').on('click', '.video-play', function videoPlay(ev) {
    ev.preventDefault();
    const { videoId } = this.dataset;
    const player = videojs(videoId);
    player.currentTime(0);
    player.on('ended', () => { player.initChildren(); });
    player.play();
  });
}

function pauseVSOTDVideos(excludeVideoId) {
  $('#carousel-wrapper').find('video').not(`#${excludeVideoId}`).each(
    function pauseVideo() {
      const player = videojs(this.id);
      if (!player.paused()) {
        player.pause();
      }
    },
  );
}

function getVideoIdFromSlider($slider) {
  const $video = $slider.find('video');
  return $video.length ? $video[0].id : null;
}

export default {
  init() {
    const $carouselWrapper = $('#carousel-wrapper');
    const hasTouch = 'ontouchstart' in window;
    let currentPaginated = 2;
    const cachedNodes = {};

    initVSOTDButtons();

    if ($('#carousel-wrapper').length > 0) {
      $('#carousel-prev-front').add('#carousel-next-front').on('click', (e) => {
        e.preventDefault();
      });

      $('#carousel-wrapper').iosSlider({
        snapToChildren: true,
        desktopClickDrag: true,
        snapSlideCenter: true,
        navPrevSelector: $('#carousel-prev-front'),
        navNextSelector: $('#carousel-next-front'),
        infiniteSlider: false,
        startAtSlide: 2,

        onSliderLoaded() {
          $('#carousel-wrapper').addClass('loaded');
        },
        onSlideChange(args) {
          $(args.sliderObject).children().removeClass('current');
          const $currentSlider = $(args.currentSlideObject);
          $currentSlider.addClass('current');
          const excludeVideoId = getVideoIdFromSlider($currentSlider);
          pauseVSOTDVideos(excludeVideoId);
          $(args.sliderContainerObject)
            .attr('data-slide', args.currentSlideNumber);
        },
        onSlideComplete(args) {
          const $currentSlider = $(args.currentSlideObject);
          const videoId = getVideoIdFromSlider($currentSlider);
          if (videoId) {
            const player = videojs(videoId);
            if (player && player.paused() && player.played().length) {
              player.play();
            }
          }

          let currentSlidePos = 2;
          const lastItem = $('#carousel-wrapper')
            .children('ul').children('li').last();

          const slides = args.data.numberOfSlides;

          if (args.currentSlideNumber === slides) { // we're at the end.
            currentPaginated += 1;
            $('#carousel-wrapper').spinner();
            $.ajax({
              url: '/front_albums/',
              data: {
                partial: true,
                before: lastItem.data('before'),
                offset: lastItem.data('number') || 5,
                count: lastItem.data('pageCount') || 5,
              },
              dataType: 'html',
            }).done((data) => {
              const lis = [];
              cachedNodes[`page${currentPaginated - 1}`] = [];
              for (let i = args.data.numberOfSlides; i > 2; i -= 1) {
                // cache already loaded node for later use if necessary.
                cachedNodes[`page${currentPaginated - 1}`]
                  .push($('#carousel-wrapper').find('li').eq(0));
                $('#carousel-wrapper').iosSlider('removeSlide', 1);
              }

              $(data).filter('li').each(function filterLi() {
                currentSlidePos += 1;

                $('#carousel-wrapper')
                  .iosSlider('addSlide', this, currentSlidePos);

                lis.push($(this));
              });

              SG.initImages($('#carousel-wrapper'));

              $(data).find('.video-js').each(function eachVideo() {
                initVideo(this);
              });

              $('#carousel-wrapper').clearSpinner();
            });
            // we're near the first slide, and we don't have any previous pages.
          } else if (args.currentSlideNumber === 1 && currentPaginated !== 2) {
            for (let i = args.data.numberOfSlides; i > 2; i -= 1) {
              $('#carousel-wrapper').iosSlider('removeSlide', i);
            }
            for (
              let i = 0;
              i <= cachedNodes[`page${currentPaginated - 1}`].length;
              i += 1
            ) {
              if (cachedNodes[`page${currentPaginated - 1}`][i]) {
                $('#carousel-wrapper')
                  .iosSlider(
                    'addSlide',
                    cachedNodes[`page${currentPaginated - 1}`][i][0],
                    -1,
                  );
              }
            }
            currentPaginated -= 1;
            $('#carousel-wrapper').clearSpinner();
          }
        },

      }).data('iosslider');
    }

    $(window).on('scroll', () => {
      // disallow scroll disruption - iOS interrupts all animations during page
      // scroll, then fires the scroll event this binding ensures the slider
      // won't get stuck between slides.
      if (!hasTouch) return;
      $carouselWrapper.data('iosslider').settings.autoSlideTransTimer = 300;
      $carouselWrapper.iosSlider('goToSlide', $carouselWrapper.data('page'));
      $carouselWrapper.data('iosslider').settings.autoSlideTransTimer = 750;
    });
  },
};
